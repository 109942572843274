import React, {
  createContext,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Accordion,
  Button,
  InputDate,
  InputSelect,
  InputText,
  SearchBox,
} from "@bluesilodev/timhutcomponents";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { formatRupiah } from "utils/numberUtils";
import { ReviewPayrollReportDataTable } from "components/common/customTables";
import { useFetchEmployeeLocationQuery } from "store/api/employeeComponentApi";
import { useFetchPayrollOptionMutation } from "store/api/payrollApi";
import dayjs from "dayjs";
import { FileDownloadSVG } from "assets/icons/FileDownloadSVG";
import { useNavigate } from "react-router-dom";
import { ContextReportAndPayslip } from "utils/constants";

const PayrollReport = ({ setSteps, steps, labelSteps }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { payrollSlice } = useSelector((state) => state);
  const [detail, setDetail] = useState(null);
  const [isEnter, setEnter] = useState("#DD7224");

  /**
   * @type {GetLocation}
   */
  const getLocation = useFetchEmployeeLocationQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const [requestPayrollOption, resultPayrollOption] =
    useFetchPayrollOptionMutation();
  const [optionPayroll, setOptionPayrollLabel] = useState([]);
  const dataLocationEmployee = useMemo(() => {
    const result = getLocation?.data?.data?.map((d) => {
      return {
        label: d?.locationName,
        value: d?.uId,
      };
    });
    return result || [];
  }, [getLocation]);
  const {
    handleBlur,
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      payrollLabel: "",
      location: "",
      employmentStatus: "option2",
      label: "",
      selectedEmployees: [],
      paymentDate: "",
    },
    onSubmit: (values) => {
      setSteps((prev) => prev + 1);
    },
  });

  useEffect(() => {
    const result = payrollSlice?.payrollSetting?.payload;
    if (result?.startDate) {
      resetForm({ values: result });
    }
  }, [payrollSlice?.payrollSetting?.payload]);
  useEffect(() => {
    if (!values?.location) {
      return;
    }
    const find = getLocation?.data?.data.find(
      (d) => d?.uId === values.location,
    );
    setFieldValue("payrollLabel", "");
    requestPayrollOption({ locationId: find?.uId }).then((r) => {
      const data = r?.data?.data?.map((d) => {
        return {
          label: d?.label,
          value: d?._id,
        };
      });
      setOptionPayrollLabel(data);
    });
  }, [values?.location, getLocation?.data?.data]);
  const data = useMemo(() => {
    if (values?.payrollLabel) {
      let result = resultPayrollOption?.data?.data?.find(
        (d) => d?._id === values?.payrollLabel,
      );
      if (search) {
        result = {
          ...result,
          data: result?.data?.filter((d) =>
            d?.user?.firstName?.toLocaleLowerCase()?.startsWith(search),
          ),
        };
      }
      setDetail(result);
      return result;
    }
    return null;
  }, [values?.payrollLabel, dataLocationEmployee, resultPayrollOption, search]);
  const total = useMemo(() => {
    const result = data?.data?.reduce((pre, v) => {
      pre += v?.total;
      return pre;
    }, 0);

    return result || 0;
  }, [data?.data]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-full mt-5">
        <div className="flex justify-between">
          <div className="flex gap-5 w-fit ">
            <InputSelect
              classname={"w-[200px]"}
              name={"location"}
              id={"location"}
              title={"Location"}
              setFieldValue={setFieldValue}
              value={values.location}
              onChange={handleChange}
              onBlur={handleBlur}
              options={dataLocationEmployee}
              defaultValue={{ label: "All Locations", value: "" }}
            />
            <InputSelect
              title={"Payroll Label"}
              classname={"w-[200px]"}
              options={optionPayroll}
              defaultValue={{ label: "All Payroll Labels", value: "" }}
              name={"payrollLabel"}
              id={"payrollLabel"}
              disabled={optionPayroll?.length === 0}
              setFieldValue={setFieldValue}
              value={values.payrollLabel}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="flex flex-row gap-5">
            <SearchBox
              onChange={(d) => {
                setSearch(d?.target?.value);
              }}
              className={"w-[308px] h-fit"}
              placeholder="Search"
            />
            <Button
              disabled={!values?.payrollLabel}
              className={"w-[200px]"}
              onMouseEnter={() => {
                setEnter("white");
              }}
              onMouseLeave={() => {
                setEnter("#DD7224");
              }}
              onClick={() => {
                navigate(`/payroll-report/detail/${values?.payrollLabel}`);
              }}
              label={
                <div className="flex gap-1 px-4">
                  <FileDownloadSVG color={isEnter} />
                  <div className="capitalize">download</div>
                </div>
              }
            />
          </div>
        </div>

        <Accordion
          title={<div className="p-2">Payroll Setup</div>}
          children={
            <div className="grid w-full grid-cols-2 gap-5 p-4">
              <div className="grid w-full grid-cols-2 gap-4 p-1">
                <div className={"w-full"}>
                  <InputText
                    disabled
                    value={data?.label ? data?.label : ""}
                    name={"label"}
                    id={"label"}
                    title={"Payroll Label *"}
                    className="flex items-center w-full gap-2"
                  />
                </div>

                <div className="w-full">
                  <InputDate
                    disabled
                    name={"paymentDate"}
                    id={"paymentDate"}
                    label="Payment Schedule *"
                    setFieldValue={setFieldValue}
                    value={
                      data?.paymentDate
                        ? dayjs(data?.paymentDate).format("DD/MM/YYYY")
                        : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className={"w-full"}>
                  <InputDate
                    disabled
                    id={"startDate"}
                    name="startDate"
                    label="Start Date Period *"
                    setFieldValue={setFieldValue}
                    value={
                      data?.startDate
                        ? dayjs(data?.startDate).format("DD/MM/YYYY")
                        : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="w-full">
                  <InputDate
                    disabled
                    id={"endDate"}
                    name="endDate"
                    label="End Date Period "
                    setFieldValue={setFieldValue}
                    value={
                      data?.endDate
                        ? dayjs(data?.endDate).format("DD/MM/YYYY")
                        : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                  />
                </div>

                <div className={"w-full mt-3"}>
                  <InputSelect
                    disabled
                    title={"Location *"}
                    id={"location"}
                    name="location"
                    setFieldValue={setFieldValue}
                    value={data?.location?.id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={dataLocationEmployee}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="capitalize grid grid-rows-[1fr_auto] h-[100px] border border-black rounded-md p-4">
                  <div>Total Disbursements</div>
                  <div className="text-2xl font-semibold">
                    {formatRupiah(total)}
                  </div>
                </div>
                <div className="capitalize grid grid-rows-[1fr_auto] h-[100px] border border-black rounded-md p-4">
                  <div>employee</div>
                  <div className="text-2xl font-semibold">
                    {data?.data?.length || 0} employee
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
      <div className="mt-5">
        <ContextReportAndPayslip.Provider value={{ detail, setDetail }}>
          <Accordion
            title={<div className="p-2">Employee</div>}
            children={
              <div className="flex flex-col w-full p-4 overflow-auto ">
                <ReviewPayrollReportDataTable dataTable={data?.data} />
              </div>
            }
          />
        </ContextReportAndPayslip.Provider>
      </div>
    </form>
  );
};
export default PayrollReport;
