import React, {
  createContext,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Accordion,
  Button,
  InputSelect,
  SearchBox,
} from "@bluesilodev/timhutcomponents";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { ReviewPayrollReportPayslipDataTable } from "components/common/customTables";
import { useFetchEmployeeLocationQuery } from "store/api/employeeComponentApi";
import {
  useFetchPayrollOptionMutation,
  useGeneratePdfPayslipDetailMutation,
  useGeneratePdfPayslipMutation,
} from "store/api/payrollApi";
import { FileDownloadSVG } from "assets/icons/FileDownloadSVG";
import { ContextReportAndPayslip } from "utils/constants";

const PayrollPayslip = ({ setSteps }) => {
  const { payrollSlice } = useSelector((state) => state);
  const { currentUser, currentRole } = useSelector((state) => state.loginSlice);

  const [detail, setDetail] = useState(null);
  const [isEnter, setEnter] = useState("#DD7224");
  const [generatePdf, { isLoading: isLoadingPdf }] =
    useGeneratePdfPayslipMutation();
  const [search, setSearch] = useState("");
  /**
   * @type {GetLocation}
   */
  const getLocation = useFetchEmployeeLocationQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const [requestPayrollOption, resultPayrollOption] =
    useFetchPayrollOptionMutation();
  const [generatePdfDetail, { isLoading: isLoadingPdfDetail }] =
    useGeneratePdfPayslipDetailMutation();
  const [optionPayroll, setOptionPayrollLabel] = useState([]);
  const dataLocationEmployee = useMemo(() => {
    const result = getLocation?.data?.data?.map((d) => {
      return {
        label: d?.locationName,
        value: d?.uId,
      };
    });
    return result || [];
  }, [getLocation]);
  const {
    handleBlur,
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      payrollLabel: "",
      location: "",
      employmentStatus: "option2",
      label: "",
      selectedEmployees: [],
      paymentDate: "",
    },
    onSubmit: (values) => {
      setSteps((prev) => prev + 1);
    },
  });
  useEffect(() => {
    resetForm();
  }, []);
  useEffect(() => {
    const result = payrollSlice?.payrollSetting?.payload;
    if (result?.startDate) {
      resetForm({ values: result });
    }
  }, [payrollSlice?.payrollSetting?.payload]);
  useEffect(() => {
    if (!values?.location) {
      return;
    }
    const find = getLocation?.data?.data.find(
      (d) => d?.uId === values.location,
    );
    setFieldValue("payrollLabel", "");
    const objectParam = { locationId: find?.uId };
    if (currentRole?.toLocaleLowerCase() === "employee" && currentUser?.uId) {
      Object.assign(
        objectParam,
        currentUser?.uId ? { idUser: currentUser?.uId } : {},
      );
    }
    requestPayrollOption(objectParam).then((r) => {
      const data = r?.data?.data?.map((d) => {
        return {
          label: d?.label,
          value: d?._id,
        };
      });
      setOptionPayrollLabel(data);
    });
  }, [values?.location, getLocation?.data?.data, currentUser]);
  const data = useMemo(() => {
    if (values?.payrollLabel) {
      let result = resultPayrollOption?.data?.data?.find(
        (d) => d?._id === values?.payrollLabel,
      );

      if (search) {
        result = {
          ...result,
          data: result?.data?.filter((d) =>
            d?.user?.firstName?.toLocaleLowerCase()?.startsWith(search),
          ),
        };
      }
      setDetail(result);
      return result;
    }
    return null;
  }, [values?.payrollLabel, dataLocationEmployee, resultPayrollOption, search]);
  const downloadZipPdfBySelect = async (dataSelect) => {
    const idParent = data?._id;
    const idSelect = dataSelect?.map((d) => {
      return d?._id;
    });
    const response = await generatePdf({
      idSelect: { idSelect: idSelect },
      idParent,
    });
    if (idSelect?.length === 0) {
      return;
    }
    const blob = new Blob([response.data], { type: "application/zip" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Timhut_employee_payslip.zip");
    document.body.appendChild(link);
    link.click();

    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);
  };
  const downloadPdfBySelect = async (dataSelect) => {
    const idParent = data?._id;
    const idSelect = dataSelect?.map((d) => {
      return d?._id;
    });
    if (dataSelect?.length > 1) {
      downloadZipPdfBySelect(dataSelect);
      return;
    }
    for (const key in idSelect) {
      const response = await generatePdfDetail({
        idSelect: { idSelect: idSelect[key] },
        idParent,
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Timhut_employee_payslip.pdf`);
      document.body.appendChild(link);
      link.click();

      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };
  useEffect(() => {
    if (currentRole?.toLocaleLowerCase() === "employee") {
      setFieldValue("selectedEmployees", data?.data);
    }
  }, [currentRole, data?.data]);
  return (
    <form onSubmit={handleSubmit}>
      <div className="w-full mt-5">
        <div className="flex justify-between">
          <div className="flex gap-5 w-fit ">
            <InputSelect
              classname={"w-[200px]"}
              name={"location"}
              id={"location"}
              title={"Location"}
              setFieldValue={setFieldValue}
              value={values.location}
              onChange={handleChange}
              onBlur={handleBlur}
              options={dataLocationEmployee}
              defaultValue={{ label: "All Locations", value: "" }}
            />
            <InputSelect
              title={"Payroll Label"}
              classname={"w-[200px]"}
              options={optionPayroll}
              defaultValue={{ label: "All Payroll Labels", value: "" }}
              name={"payrollLabel"}
              id={"payrollLabel"}
              disabled={optionPayroll?.length === 0}
              setFieldValue={setFieldValue}
              value={values.payrollLabel}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="flex flex-row gap-5">
            <SearchBox
              onChange={(d) => {
                setSearch(d?.target?.value);
              }}
              className={"w-[308px] h-fit"}
              placeholder="Search"
            />
            <Button
              className={"w-[200px] relative overflow-hidden"}
              disabled={
                !values?.payrollLabel || values.selectedEmployees?.length === 0
              }
              onMouseEnter={() => {
                setEnter("white");
              }}
              onMouseLeave={() => {
                setEnter("#DD7224");
              }}
              onClick={() => {
                downloadPdfBySelect(values?.selectedEmployees);
              }}
              label={
                <>
                  {isLoadingPdfDetail && (
                    <div
                      className="absolute top-0 right-0 bottom-0 left-0
                   text-white bg-[#DD7224] flex items-center justify-center"
                    >
                      <div>loading...</div>
                    </div>
                  )}

                  {isLoadingPdf && (
                    <div
                      className="absolute top-0 right-0 bottom-0 left-0
                   text-white bg-[#DD7224] flex items-center justify-center"
                    >
                      <div>loading...</div>
                    </div>
                  )}
                  <div className="flex px-4 gap-1 ">
                    <FileDownloadSVG color={isEnter} />
                    <div className="capitalize">download</div>
                  </div>
                </>
              }
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <ContextReportAndPayslip.Provider value={{ detail, setDetail }}>
          <Accordion
            title={<div className="p-2 capitalize">overview</div>}
            children={
              <div className="p-4 w-full flex flex-col overflow-auto ">
                <ReviewPayrollReportPayslipDataTable
                  setFieldValue={setFieldValue}
                  dataTable={data?.data}
                />
              </div>
            }
          />
        </ContextReportAndPayslip.Provider>
      </div>
    </form>
  );
};
export default PayrollPayslip;
